<template>
  <div>
    <h2 class="headline font-weight-bold mb-4">
      <v-icon class="mr-2" color="primary" large>mdi-school</v-icon>
      {{ $t("app.student") }}
    </h2>

    <v-row>
      <v-col cols="12" sm="6" lg="3">
        <v-card
          :class="darkmode ? 'black' : 'white'"
          class="d-flex pa-5 mb-4 rounded-lg flex-column"
          flat
        >
          <div class="title font-weight-bold">
            {{ $t("dashboard.absent") }}
          </div>
          <div class="d-flex align-center">
            <div>
              <div
                class="gradient-primary my-2 rounded-circle d-flex justify-center align-center"
                style="width: 70px; height: 70px"
              >
                <v-icon color="white" large>
                  mdi-file-document-box
                </v-icon>
              </div>
            </div>
            <v-spacer />
            <div class="text-center">
              <div class="display-1 font-weight-bold">
                {{ absentStudents.count }}
              </div>
              <div class="title font-weight-bold">
                {{ $t("app.student") }}
              </div>
            </div>
          </div>
          <div class="caption d-flex align-center">
            <v-icon color="white" small class="grey rounded-xl mr-2">
              mdi-exclamation
            </v-icon>
            {{ absentStudents.presentage }}%
            {{ $t("dashboard.absent_students") }}
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <v-card
          :class="darkmode ? 'black' : 'white'"
          class="d-flex pa-5 mb-4 rounded-lg flex-column"
          flat
        >
          <div class="title font-weight-bold">
            {{ $t("dashboard.prospective_students") }}
          </div>
          <div class="d-flex align-center mb-5">
            <div>
              <div
                class="gradient-primary my-2 rounded-circle d-flex justify-center align-center"
                style="width: 70px; height: 70px"
              >
                <v-icon color="white" large>
                  mdi-account-multiple
                </v-icon>
              </div>
            </div>
            <v-spacer />
            <div class="text-center">
              <div class="display-1 font-weight-bold">
                {{ prospectiveStudents.count }}
              </div>
              <div class="title font-weight-bold">
                {{ $t("app.student") }}
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <v-card
          :class="darkmode ? 'black' : 'white'"
          class="d-flex pa-5 mb-4 rounded-lg flex-column"
          flat
        >
          <div class="title font-weight-bold">
            {{ $t("dashboard.active_students") }}
          </div>
          <div class="d-flex align-center">
            <div>
              <div
                class="gradient-primary my-2 rounded-circle d-flex justify-center align-center"
                style="width: 70px; height: 70px"
              >
                <v-icon color="white" large>
                  mdi-account-check
                </v-icon>
              </div>
            </div>
            <v-spacer />
            <div class="text-center">
              <div class="display-1 font-weight-bold">
                {{ activeStudents.count }}
              </div>
              <div class="title font-weight-bold">
                {{ $t("app.student") }}
              </div>
            </div>
          </div>
          <div class="caption d-flex align-center">
            <v-icon color="white" small class="grey rounded-xl mr-2">
              mdi-exclamation
            </v-icon>
            {{ $t("app.school_year") }}
            {{ activeStudents.school_year_master.start_year }} /
            {{ activeStudents.school_year_master.end_year }}
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <v-card
          :class="darkmode ? 'black' : 'white'"
          class="d-flex pa-5 mb-4 rounded-lg flex-column"
          flat
        >
          <div class="title font-weight-bold">
            {{ $t("dashboard.students_graduate") }}
          </div>
          <div class="d-flex align-center">
            <div>
              <div
                class="gradient-primary my-2 rounded-circle d-flex justify-center align-center"
                style="width: 70px; height: 70px"
              >
                <v-icon color="white" large>
                  mdi-account-remove
                </v-icon>
              </div>
            </div>
            <v-spacer />
            <div class="text-center">
              <div class="display-1 font-weight-bold">
                {{ studentsGraduate.count }}
              </div>
              <div class="title font-weight-bold">
                {{ $t("app.student") }}
              </div>
            </div>
          </div>
          <div class="caption d-flex align-center">
            <v-icon color="white" small class="grey rounded-xl mr-2">
              mdi-exclamation
            </v-icon>
            {{ $t("app.school_year") }}
            {{ studentsGraduate.school_year_master.start_year }} /
            {{ studentsGraduate.school_year_master.end_year }}
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        :class="$vuetify.breakpoint.mobile && 'mb-5'"
        cols="12"
        lg="8"
        xl="9"
      >
        <v-card
          :class="darkmode ? 'black' : 'white'"
          min-height="500"
          flat
          class="pa-4 rounded-lg"
        >
          <div class="title font-weight-bold mb-4 d-flex flex-row align-center">
            {{ $t("dashboard.violation") }}
            <v-spacer />
            <v-select
              v-model="modelFilter"
              :items="filters"
              item-text="name"
              item-value="type"
              hide-details
              outlined
              dense
              class="select-150"
              @change="getStudentsViolation"
            />
          </div>
          <BarChart
            v-if="!loadingBar"
            :labels="studentsViolation.labels"
            :datasets="studentsViolation.datasets"
          />
          <v-card
            v-else
            min-height="400"
            class="d-flex align-content-center flex-wrap justify-center elevation-0"
          >
            <v-progress-circular size="50" color="primary" indeterminate />
          </v-card>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" xl="3">
        <v-card
          :class="darkmode ? 'black' : 'white'"
          min-height="500"
          flat
          class="pa-4 title font-weight-bold rounded-lg"
        >
          <div class="title font-weight-bold mb-4 d-flex flex-row align-center">
            {{ $t("dashboard.total_students") }}
            <v-spacer />
            <v-select
              v-model="modelSchoolYear"
              :items="schoolYear"
              :item-text="year => `${year.start_year} / ${year.end_year}`"
              :loading="loadingSchoolYear"
              item-value="id"
              hide-details
              outlined
              dense
              class="select-150"
              @change="getStudentsActiveGrade(modelSchoolYear)"
            />
          </div>
          <PieChart
            v-if="!loadingDoughnut"
            :labels="studentsActiveGrade.labels"
            :datasets="studentsActiveGrade.datasets"
          />
          <v-card
            v-else
            min-height="400"
            class="d-flex align-content-center flex-wrap justify-center elevation-0"
          >
            <v-progress-circular size="50" color="primary" indeterminate />
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  activeStudents,
  absentStudents,
  prospectiveStudents,
  studentsGraduate,
  studentsViolation,
  studentsActiveGrade
} from "@/api/admin/dashboard";
import { get_school_year_list } from "@/api/admin/schoolClass";

export default {
  components: {
    BarChart: () => import("@/components/Chart/BarChart"),
    PieChart: () => import("@/components/Chart/PieChart")
  },
  created() {
    this.getActiveStudents();
    this.getAbsentStudents();
    this.getProspectiveStudents();
    this.getStudentsGraduate();
    this.getStudentsViolation();
    this.getSchoolYear();
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  },
  data() {
    return {
      loadingBar: false,
      loadingDoughnut: false,
      loadingSchoolYear: false,
      modelFilter: "weekly",
      modelSchoolYear: 0,
      schoolYear: [],
      filters: [
        { type: "weekly", name: this.$i18n.t("dashboard.weekly") },
        { type: "monthly", name: this.$i18n.t("dashboard.monthly") },
        { type: "annual", name: this.$i18n.t("dashboard.annual") }
      ],
      absentStudents: {
        count: 0,
        presentage: 0,
        date: ""
      },
      prospectiveStudents: {
        count: 0
      },
      studentsGraduate: {
        count: 0,
        school_year_master: {
          id: 0,
          institution: "",
          start_year: 0,
          end_year: 0
        }
      },
      studentsViolation: {
        labels: [],
        datasets: []
      },
      studentsActiveGrade: {
        labels: [],
        datasets: []
      },
      activeStudents: {
        count: 0,
        school_year_master: {
          id: 0,
          institution: "",
          start_year: 0,
          end_year: 0
        }
      }
    };
  },
  methods: {
    getSchoolYear() {
      this.loadingSchoolYear = true;
      get_school_year_list(true)
        .then(res => {
          if (res.data.code) {
            let r = res.data.data;
            const schoolYearActive = r.find(d => d.status == "ACTIVE");
            if (schoolYearActive) {
              this.modelSchoolYear = schoolYearActive.id;
            }
            this.schoolYear = r;
          } else {
            this.snackBar(true, res.data.message);
          }
          this.getStudentsActiveGrade(this.modelSchoolYear);
        })
        .catch(e => console.log("getSchoolYear()\n", e))
        .finally(() => (this.loadingSchoolYear = false));
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
    },
    getActiveStudents() {
      activeStudents()
        .then(res => {
          const data = res.data;
          if (data.code) {
            // handle if school_year_master null
            if (data.data.school_year_master) {
              this.activeStudents = data.data;
            }
          } else this.snackBar(true, res.data.message);
        })
        .catch(e => console.error(e));
    },
    generateDatasets(data, isBarChart) {
      const datasets = [
        {
          backgroundColor: "#3A9EDD",
          pointBackgroundColor: "white",
          pointBorderColor: "#249EBF"
        },
        {
          backgroundColor: "#FF6099",
          pointBackgroundColor: "white",
          pointBorderColor: "#249EBF"
        },
        {
          backgroundColor: "#FFB131",
          pointBackgroundColor: "white",
          pointBorderColor: "#249EBF"
        },
        {
          backgroundColor: "#4CAF50",
          pointBackgroundColor: "white",
          pointBorderColor: "#249EBF"
        },
        {
          backgroundColor: "#9C27B0",
          pointBackgroundColor: "white",
          pointBorderColor: "#249EBF"
        },
        {
          backgroundColor: "#B4B0B5",
          pointBackgroundColor: "white",
          pointBorderColor: "#249EBF"
        },
        {
          backgroundColor: "#020C9E",
          pointBackgroundColor: "white",
          pointBorderColor: "#249EBF"
        },
        {
          backgroundColor: "#C70411",
          pointBackgroundColor: "white",
          pointBorderColor: "#249EBF"
        },
        {
          backgroundColor: "#05D2ED",
          pointBackgroundColor: "white",
          pointBorderColor: "#249EBF"
        },
        {
          backgroundColor: "#F2FF03",
          pointBackgroundColor: "white",
          pointBorderColor: "#249EBF"
        }
      ];
      if (isBarChart) {
        return data.map((item, index) => ({
          ...datasets[index],
          label: item.name,
          data: item.datasets
        }));
      } else {
        let result = [];
        for (let index = 0; index < data; index++) {
          result.push(datasets[index].backgroundColor);
        }
        return result;
      }
    },
    getStudentsActiveGrade(shoolYearMaster) {
      this.loadingDoughnut = true;
      const body = {
        shool_year_master: shoolYearMaster
      };
      studentsActiveGrade(body)
        .then(res => {
          if (res.data.code) {
            const result = res.data.data;
            this.studentsActiveGrade.labels = result.diagram.map(
              item => `${this.$i18n.t("app.grade")} ${item.grade_name}`
            );
            this.studentsActiveGrade.datasets = [
              {
                fill: true,
                backgroundColor: this.generateDatasets(result.grades.length),
                data: result.diagram.map(item => item.count)
              }
            ];
          } else {
            this.snackBar(true, res.data.message);
          }
        })
        .catch(e =>
          console.error("getStudentsActiveGrade(shoolYearMaster)\n", e)
        )
        .finally(() => (this.loadingDoughnut = false));
    },
    getStudentsViolation() {
      this.loadingBar = true;
      studentsViolation({ type: this.modelFilter })
        .then(res => {
          if (res.data.code) {
            const result = res.data.data;
            this.studentsViolation.labels = result.diagram.map(
              item => item.label
            );
            const dataChart = [];
            result.grade.map((item, index) => {
              const name = `${this.$i18n.t("app.grade")} ${item.grade}`;
              let datasets = [];
              result.diagram.map(item => datasets.push(item.bar[index].count));
              dataChart[index] = { name: name, datasets: datasets };
            });
            this.studentsViolation.datasets = this.generateDatasets(
              dataChart,
              true
            );
          } else {
            this.snackBar(true, res.data.message);
          }
        })
        .catch(e => console.error("getStudentsViolation()\n", console.error(e)))
        .finally(() => (this.loadingBar = false));
    },
    getStudentsGraduate() {
      studentsGraduate()
        .then(res => {
          const data = res.data;
          if (data.code) {
            if (data.data.school_year_master) {
              this.studentsGraduate = res.data.data;
            }
          } else this.snackBar(true, res.data.message);
        })
        .catch(e => console.error("getStudentsGraduate()\n", e));
    },
    getAbsentStudents() {
      absentStudents()
        .then(res => {
          if (res.data.code) this.absentStudents = res.data.data;
          else this.snackBar(true, res.data.message);
        })
        .catch(e => console.error("getAbsentStudents()\n", e));
    },
    getProspectiveStudents() {
      prospectiveStudents()
        .then(res => {
          if (res.data.code) this.prospectiveStudents = res.data.data;
          else this.snackBar(true, res.data.message);
        })
        .catch(e => console.error("getProspectiveStudents()\n", e));
    }
  }
};
</script>

import request from "@/utils/request";

export function activeStudents() {
  return request({
    method: "post",
    url: "dashboard_admin/student_active"
  });
}

export function studentsGraduate() {
  return request({
    method: "post",
    url: "dashboard_admin/student_graduated"
  });
}

export function prospectiveStudents() {
  return request({
    method: "post",
    url: "dashboard_admin/student_applicant"
  });
}

export function studentsActiveGrade(data) {
  return request({
    method: "post",
    url: "dashboard_admin/student_active_grade",
    data: data
  });
}

export function absentStudents() {
  return request({
    method: "post",
    url: "dashboard_admin/student_absence"
  });
}

export function studentsViolation(data) {
  return request({
    method: "post",
    url: "dashboard_admin/student_conseling_point",
    data: data
  });
}

export function employeeAbsent(data) {
  return request({
    method: "post",
    url: "dashboard_admin/employee_absence",
    data: data
  });
}

export function employeeApplicant(data) {
  return request({
    method: "post",
    url: "dashboard_admin/employee_applicant",
    data: data
  });
}

export function employeeActive(data) {
  return request({
    method: "post",
    url: "dashboard_admin/employee_active",
    data: data
  });
}

export function employeeMutation(data) {
  return request({
    method: "post",
    url: "dashboard_admin/employee_mutation",
    data: data
  });
}

export function employeeViolation() {
  return request({
    method: "post",
    url: "dashboard_admin/employee_conseling_point"
  });
}
